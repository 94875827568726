import React from 'react';

export default class InquirySection extends React.Component {
  render() {
    
    const { title, description, inputFirstName, inputLastName, inputContact, inputEmail, inputInquiryType, inputMessage, inputNewsletter, inputSubmit } = this.props;
    return (
      <section id="inquiry" className="inquiry-section pt-5 pb-5">
        <div className="container mt-3 mb-3 inquiry-section-container">
          <div className="row justify-content-between">
            <div className="col-lg-6 mb-4">
              <h2 className="title">
               {title}
              </h2>
              <p className="desc">
                {description}
              </p>
            </div>
            <div className="col-lg-5">
              <form
                className="default-form"
                action="https://gmail.us5.list-manage.com/subscribe/post?u=8888c103642d087afb1f42fef&amp;id=72e829b037&SIGNUP=Homepage"
                method="post"
              >
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="FNAME"
                      placeholder={inputFirstName}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="LNAME"
                      placeholder={inputLastName}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input type="text" name="PHONE" placeholder={inputContact} />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="EMAIL"
                      placeholder={inputEmail}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <select name="INQUIRY" required defaultValue={inputInquiryType[0].value}>
                      {inputInquiryType.map((option, i) => (
                         <option value={option.value} disabled={i == 0 ? true : false} key={i}>
                         {option.name}
                       </option>
                      ))}
                    </select>
                    <img src="/images/arrow.png" className="select-arrow" />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="MESSAGE"
                      rows="3"
                      placeholder={inputMessage}
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-md-8 mt-3 mb-2">
                        <label className="checkbox-label">
                          <input type="checkbox" required /> {inputNewsletter}
                        </label>
                      </div>
                      <div className="col-md-4 mt-3 mb-2">
                        <button className="btn w-100" type="submit">
                        {inputSubmit}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
