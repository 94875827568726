import React from 'react';
import { Link } from 'gatsby';

export default class FeaturesSection extends React.Component {
  render() {
    const { featureSection } = this.props;
    return (
      <section className="features-section pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            {featureSection.map((feature, i) =>(
              <div className="col-lg-4 col-md-6 features-section-container" key={i}>
                <h1 className="section-title">{feature.title}</h1>
                <div className="section-logo">
                  <img height="150" src={feature.featureIcon.file.url} alt="" />
                </div>
                <p className="section-desc">
                  {feature.description.description}
                </p>
                <Link to={feature.buttonLink}>
                  <button className="btn">{feature.buttonText}</button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
