import React from 'react';
import { Link } from 'gatsby';
import Carousel from 'react-bootstrap/Carousel';

export default class EventsSection extends React.Component {
  render() {
    const { eventsSection, title, description, linkText, link } = this.props;

    const eventsSectionDiv = [];

    const eventsSectionMobile = [];

    // Events Specific loop for mobile posts
    for (let i = 0; i < eventsSection[0].node.eventsSection.length; i++) {

      eventsSectionMobile.push(
        <Carousel.Item key={eventsSection[0].node.eventsSection.length + Math.random(1,10)}>
          <div className="container pt-4 event-container">
            <div className="row">
              <div key={i} className="col-md-12 text-center">
                <div className="venue-whole venue-details text-center">
                  <Link
                    to={`/event-details/${eventsSection[0].node.eventsSection[i].slug}`}
                  >
                    <div className="venue-height">
                      <img
                        src={
                          eventsSection[0].node.eventsSection[i].featuredImage
                            .file.url
                        }
                      />
                      <p className="event-schedule mt30 white font-gtaM">
                        {eventsSection[0].node.eventsSection[i].eventDate}
                      </p>
                      <h2 className="event-title mt10 brown font-tri">
                        {eventsSection[0].node.eventsSection[i].infoSectionTitle}
                      </h2>
                      <p className="white mt10 event-desc">
                        {
                          eventsSection[0].node.eventsSection[i].blurb
                        }
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      );

    }

    // Events Specific posts for loop
    for (let i = 0; i <= 3; i++) {

      eventsSectionDiv.push(
        <Carousel.Item key={eventsSection[0].node.eventsSection.length + Math.random(11,20)}>
          <div className="container pt-4 event-container">
            <div className="row">
              <div key={i} className="col-md-6 text-center">
                <div className="venue-whole venue-details text-center">
                  <Link
                    to={`/event-details/${eventsSection[0].node.eventsSection[i].slug}`}
                  >
                    <div className="venue-height">
                      <img
                        src={
                          eventsSection[0].node.eventsSection[i].featuredImage
                            .file.url
                        }
                      />
                      <p className="event-schedule mt30 white font-gtaM">
                        {eventsSection[0].node.eventsSection[i].eventDate}
                      </p>
                      <h2 className="event-title mt10 brown font-tri">
                        {eventsSection[0].node.eventsSection[i].infoSectionTitle}
                      </h2>
                      <p className="white mt10 event-desc">
                        {
                          eventsSection[0].node.eventsSection[i].blurb
                        }
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              {/* ++i is for the second column's data */}
              <div key={++i} className="col-md-6 text-center">
                <div className="venue-whole venue-details text-center">
                  <Link
                    to={`/event-details/${eventsSection[0].node.eventsSection[i].slug}`}
                  >
                    <div className="venue-height">
                      <img
                        src={
                          eventsSection[0].node.eventsSection[i].featuredImage
                            .file.url
                        }
                      />
                      <p className="event-schedule mt30 white font-gtaM">
                        {eventsSection[0].node.eventsSection[i].eventDate}
                      </p>
                      <h2 className="event-title mt10 brown font-tri">
                        {eventsSection[0].node.eventsSection[i].infoSectionTitle}
                      </h2>
                      <p className="white mt10 event-desc">
                        {
                          eventsSection[0].node.eventsSection[i].blurb
                        }
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      );
    }

    return (
      <section className="events-section pt-5 pb-5">
        <div className="container">
          <div className="events-header">
            <h1>{title}</h1>
            <p>
            {description}
            </p>
          </div>

          <Carousel className="gray-arrows hidden-indicators d-none d-sm-none d-md-block">
            {eventsSectionDiv}
          </Carousel>

          <Carousel className="d-sm-block d-md-none">
            {eventsSectionMobile}
          </Carousel>

          <div className="events-bottom">
            <a href={link}>
              <button className="btn">{linkText}</button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}
