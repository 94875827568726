import React from 'react';

export default class HistorySection extends React.Component {


  render() {
    const { title, description, linkName, link, image } = this.props;
    return (
      <section className="history-section pt-5 pb-5">
        <div className="container mt-5 mb-5 history-section-container">
          <img width="260" src={image} alt="Mansion" />
          <h1 className="section-title">
            {title}
          </h1>
          <p className="section-desc">
          {description}
          </p>
          <a href={link}>
            <button className="btn">{linkName}</button>
          </a>
        </div>
      </section>
    );
  }
}
