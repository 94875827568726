import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'gatsby';

export default class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: this.props.slides,
    };
  }

  heroSlider(slides) {
    return slides.map((slide, i) => {
      const { color, slideTitle, buttonLink, buttonText } = slide.node;
      const slideImage = slide.node.slideImage.file.url;
      const sliderDescription = slide.node.sliderDescription.sliderDescription;
      return (
          <Carousel.Item>
            <div
              key={i}
              className="hero-section"
              style={{ backgroundImage: `url(${slideImage})` }}
            >
              <div className="container">
                <div className="d-flex justify-content-end">
                  <div className={`col-lg-4 col-md-6 slide-details ${color}`}>
                    <h1 className="slide-title">{slideTitle}</h1>
                    <p className="slide-desc mb-5">{sliderDescription}</p>
                    <Link to={buttonLink}>
                      <button className="btn">{buttonText}</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
      )
    });
  }

  render() {
    return (
      <Carousel className="d-md-block">
        {this.heroSlider(this.state.slides)}
      </Carousel>
    );
  }
}