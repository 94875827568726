import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import EventsSection from '../components/events-section';
import NavSection from '../components/nav-section';
// import NavSectionTwo from '../components/nav-second';
import FeaturesSection from '../components/features-section';
import Footer from '../components/footer';
import HeroSection from '../components/home-hero';
import HistorySection from '../components/history-section';
import InquirySection from '../components/inquiry-section';
import Layout from '../components/layout';
import TestimonialSection from '../components/testimonial-section';

class RootIndex extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      let hash = window.location.hash.replace('#', '');
      let target = document.getElementById(hash);
      target && target.scrollIntoView(true);
    }
  }

  render() {
    const siteTitle           = get(this, 'props.data.site.siteMetadata.title');
    const slides              = get(this, 'props.data.allContentfulHeroSlider.edges');
    const navMenus            = get(this, 'props.data.allContentfulNavigation.edges');
    const featureSection      = get(this, 'props.data.allContentfulHomeFeature.nodes')
    const eventsSection        = get(this, 'props.data.allContentfulHomeEventsSection.edges');
    const testimonialSection  = get(this, 'props.data.contentfulTestimonialSection')
    const {
      historySectionTitle,
      historySectionDescription, 
      historySectionLinkName, 
      historySectionLink,
      historySectionImage,
      eventSectionTitle,
      eventSectionDescription,
      eventSectionButtonText,
      eventSectionButtonLink,
      inquirySectionTitle,
      inquirySectionDescription,
      inquirySectionInputFirstName, 
      inquirySectionInputLastName, 
      inquirySectionInputMessage,
      inquirySectionInputNewsletter,
      inquirySectionInputSubmit,
      inquirySectionInputInquiryTypeOptions,
      inquirySectionInputEmail,
      inquirySectionInputContact
    } = get(this, 'props.data.allContentfulHomeHomePage.edges')[0].node


    const footer = get(this, 'props.data.allContentfulFooter.edges')

    return (
      <Layout>
        <div className="sm-transNav">
          <NavSection navMenus={navMenus} navTheme="nav-transparent" />
        </div>
        <Helmet title={siteTitle} />
        <div className="sm-heroslider">
          <HeroSection slides={slides} />
        </div>
        <HistorySection 
          title={historySectionTitle}
          description={historySectionDescription.historySectionDescription}
          linkName={historySectionLinkName}
          link={historySectionLink}
          image={historySectionImage.file.url}
          />
        <FeaturesSection featureSection={featureSection}/>
        <EventsSection 
          eventsSection={eventsSection}
          title={eventSectionTitle}
          description={eventSectionDescription.eventSectionDescription}
          linkText={eventSectionButtonText}
          link={eventSectionButtonLink}
          />
        <TestimonialSection testimonialSection={testimonialSection}/>
        <InquirySection 
          title={inquirySectionTitle}
          description={inquirySectionDescription.inquirySectionDescription}
          inputFirstName={inquirySectionInputFirstName}
          inputLastName={inquirySectionInputLastName}
          inputContact={inquirySectionInputContact}
          inputEmail={inquirySectionInputEmail}
          inputInquiryType={inquirySectionInputInquiryTypeOptions}
          inputMessage={inquirySectionInputMessage}
          inputNewsletter={inquirySectionInputNewsletter}
          inputSubmit={inquirySectionInputSubmit}
          />
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulHeroSlider(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slideTitle
          color
          buttonLink
          buttonText
          slideImage {
            file {
              url
            }
          }
          sliderDescription {
            sliderDescription
          }
        }
      }
    }
    allContentfulHomeHomePage {
      edges {
        node {
          historySectionTitle
          historySectionDescription {
            historySectionDescription
          }
          historySectionLinkName
          historySectionLink
          historySectionImage {
            file {
              url
            }
          }
          eventSectionTitle
          eventSectionDescription {
            eventSectionDescription
          }
          eventSectionButtonText
          eventSectionButtonLink
          inquirySectionTitle
          inquirySectionDescription {
            inquirySectionDescription
          }
          inquirySectionInputFirstName
          inquirySectionInputLastName
          inquirySectionInputMessage
          inquirySectionInputNewsletter
          inquirySectionInputSubmit
          inquirySectionInputInquiryTypeOptions {
            name
            value
          }
          inquirySectionInputEmail
          inquirySectionInputContact
        }
      }
    }
    allContentfulHomeFeature(limit: 3, sort: { fields: createdAt, order: ASC }) {
      nodes {
        title
        description {
          description
        }
        featureIcon {
          file {
            url
          }
        }
        buttonText
        buttonLink
      }
    }

    allContentfulHomeEventsSection {
      edges {
        node {
          eventsSection {
            slug
            featuredImage {
              file {
                url
              }
            }
            eventDate(formatString: "MMMM DD, YYYY / h:mm A")
            infoSectionTitle
            blurb
            eventSpecificQuote {
              eventSpecificQuote
              childMarkdownRemark {
                excerpt(pruneLength: 100)
              }
            }
          }
        }
      }
    }
    contentfulTestimonialSection {
      testimony {
        testimony
      }
      testimonyBy
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
  
`;
